import { NgxLoggerLevel } from 'ngx-logger';
import { Environment } from './env.model';

export const environment: Environment = {
  production: false,
  name: 'dev',
  apiURL: 'https://dev-aws-api.geteazi.de',
  defaultLanguage: 'de',
  defaultCountryCode: 'de',
  supportedLanguages: ['de'],
  basicAuthUser: 'ruvpinpoint',
  basicAuthPassword: 'ThisIsAVerySecurePassword!',
  codeParameter: 'code',
  verificationDateFormat: 'DD.MM.YYYY',
  logLevel: NgxLoggerLevel.DEBUG,
  featureFlags: {
    eTracker: {
      enabled: false,
      secureCode: '5dV3sb',
      blockCookies: true,
    },
  },
};
