import { Component, OnDestroy, OnInit } from '@angular/core';
import { CaseType } from '@app/states/case/case.model';
import { KKINK_IDS } from '@app/states/case/kkink/kkink-case-helper';
import { OrganizationStateService } from '@app/states/organization/organization-state.service';
import { Organization } from '@app/states/organization/organization.model';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'ruv-expired-link',
  templateUrl: './expired-link.component.html',
  styleUrls: ['./expired-link.component.scss'],
})
export class ExpiredLinkComponent implements OnInit, OnDestroy {
  public readonly CaseType = CaseType;
  public currentCaseType: CaseType | undefined;
  private organization$: Observable<Organization | undefined>;
  private subscription: Subscription | undefined;

  constructor(organizationStateService: OrganizationStateService) {
    this.organization$ = organizationStateService.organization$;
  }

  ngOnInit(): void {
    this.subscription = this.organization$.subscribe((org) => {
      const orgaId = org?.id ?? '';

      if (orgaId === 'ruv-rtpo') this.currentCaseType = CaseType.RTP;
      if (KKINK_IDS.includes(orgaId)) this.currentCaseType = CaseType.KKINK;
    });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
