import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppRoutes } from '@app/app-routes';
import { APIEndpoints } from '@app/backend/api-endpoints';
import { BYPASS_ERROR_FLAG } from '@app/backend/backend.service';
import { OrganizationStateService } from '@app/states/organization/organization-state.service';
import { DEFAULT_ORG, Organization } from '@app/states/organization/organization.model';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap, take, tap } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private currentOrg!: Organization | undefined;

  constructor(
    private router: Router,
    private state: OrganizationStateService,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.context.get(BYPASS_ERROR_FLAG) === true) {
      return next.handle(request);
    }

    return this.state.organization$.pipe(
      take(1),
      tap((organization) => {
        this.currentOrg = organization;
      }),
      switchMap(() => next.handle(request)),
      catchError((error: HttpErrorResponse) => {
        const orgId = this.currentOrg?.id ?? DEFAULT_ORG.id;
        switch (error.status) {
          case HttpStatusCode.Conflict:
            if (this.isEndpointType(request, APIEndpoints.Case)) {
              this.router.navigate([orgId, AppRoutes.AlreadySent]);
            }
            break;
          case HttpStatusCode.Gone:
            if (this.isEndpointType(request, APIEndpoints.Mail)) {
              this.router.navigate([orgId, AppRoutes.ExpiredLink]);
            }
            break;
          case HttpStatusCode.NotFound:
            if (this.isEndpointType(request, APIEndpoints.Mail)) {
              this.router.navigate([orgId, AppRoutes.InvalidLink]);
            }
            break;
          case HttpStatusCode.Unauthorized:
          case HttpStatusCode.Forbidden:
            break;
          default:
            this.router.navigate([orgId, AppRoutes.Error]);
            break;
        }
        return throwError(() => error);
      }),
    );
  }

  private isEndpointType(request: HttpRequest<unknown>, type: APIEndpoints) {
    return request.url.includes(type);
  }
}
