import { Component } from '@angular/core';
import { OrganizationStateService } from '@app/states/organization/organization-state.service';
import { Organization } from '@app/states/organization/organization.model';
import { Observable } from 'rxjs';

@Component({
  selector: 'ruv-data-privacy',
  templateUrl: './data-privacy.component.html',
  styleUrls: ['./data-privacy.component.scss'],
})
export class DataPrivacyComponent {
  public organization$!: Observable<Organization | undefined>;

  constructor(stateService: OrganizationStateService) {
    this.organization$ = stateService.organization$;
  }
}
