import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { NavigationModule } from '@progress/kendo-angular-navigation';
import { PopupModule } from '@progress/kendo-angular-popup';
import { SharedModule } from '@shared/shared.module';
import { FooterComponent } from './footer.component';
import { ContactPopupComponent } from './contact-popup/contact-popup.component';

@NgModule({
  declarations: [FooterComponent, ContactPopupComponent],
  imports: [CommonModule, NavigationModule, ButtonsModule, PopupModule, TranslateModule.forChild(), SharedModule],
  exports: [FooterComponent],
})
export class FooterModule {}
