import { AbstractControl, ValidationErrors } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { isValidIBAN } from 'ibantools';
export function ibanValidator(control: AbstractControl, field: FormlyFieldConfig): ValidationErrors | null {
  if (!control.value) {
    return null;
  }
  const ibanNumber = control.value as string;
  const validIBAN = isValidIBAN(ibanNumber);
  return validIBAN ? null : { iban: true };
}
