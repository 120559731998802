import { DefaultCase, Consent, ContactInfo, ConsentType } from './default-case.model';

export class DefaultCaseFactory {
  public static createEmpty(): DefaultCase {
    const newCase: DefaultCase = {
      reference: '',
      journeyId: '',
      verificationCode: '',
      customer: {
        salutation: '',
        firstName: '',
        lastName: '',
        birthDate: '01.01.2000',
        address: {
          city: '',
          postalCode: '',
          street: '',
          streetNumber: '',
          countryCode: 'DE',
        },
        private: {
          phoneNumber: '',
          mobileNumber: '',
          emailAddress: '',
        },
        business: {
          phoneNumber: '',
          mobileNumber: '',
          emailAddress: '',
        },
        consent: {
          email: {
            customerConsent: ConsentType.NO_COMMENT,
          },
          phone: {
            customerConsent: ConsentType.NO_COMMENT,
          },
        },
      },
    };
    return newCase;
  }

  public static createWithContactData(
    privateContact: ContactInfo,
    businessContact: ContactInfo,
    consent?: Consent,
  ): DefaultCase {
    const emptyCase = DefaultCaseFactory.createEmpty();
    let newCase = {
      ...emptyCase,
      customer: {
        ...emptyCase.customer,
        private: privateContact,
        business: businessContact,
      },
    };

    if (consent) {
      newCase = {
        ...newCase,
        customer: {
          ...newCase.customer,
          consent: consent,
        },
      };
    }

    return newCase;
  }

  public static createWithConsent(consent: Consent): DefaultCase {
    const emptyCase = DefaultCaseFactory.createEmpty();
    let newCase = {
      ...emptyCase,
      customer: {
        ...emptyCase.customer,
        consent: consent,
      },
    };
    return newCase;
  }
}

export const EMPTY_CASE = DefaultCaseFactory.createEmpty();
