import { DefaultCase } from '../default/default-case.model';

export enum ExperimentId {
  TREE = 'TREE',
  AMAZON = 'AMAZON',
  WALLET = 'WALLET',
  DEW = 'DEW',
}

export interface Experiment {
  id: ExperimentId;
  mailHeadline?: string;
  mailText: string;
  mailImage: string;
  mailImageBase64?: string;
  landingPageHeadline?: string;
  landingPageText: string;
  landingPageImage: string;
}

export interface SwitcherCase extends DefaultCase {
  experimentId: ExperimentId;
}
