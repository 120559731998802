import { Pipe, PipeTransform } from '@angular/core';
import { DefaultCase } from '@app/states/case/default/default-case.model';

@Pipe({ name: 'parseEmail' })
export class ParseEmailPipe implements PipeTransform {
  transform(caseObject: DefaultCase) {
    const { customer } = caseObject;

    const emailAddress = customer.private?.emailAddress ?? customer.business?.emailAddress;

    return emailAddress;
  }
}
