import { AbstractControl, ValidationErrors } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';

export function specialCaseValidator(control: AbstractControl, field: FormlyFieldConfig): ValidationErrors | null {
  if (!control.value) {
    return null;
  }

  const invalidCharacters = /[!^"§$%&{}\[\]?#='`´~+@*_<>°|€µ:;,]/.test(control.value);
  return invalidCharacters ? { specialCase: true } : null;
}
