import { Case } from './case.model';

export class CaseHelper<T extends Case> {
  public preProcessCase(caseObject: T): T {
    return caseObject;
  }

  public postProcessCase(caseObject: T): any {
    return caseObject;
  }
}
