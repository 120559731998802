import { AbstractControl, ValidationErrors } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';

class RtpOnboardingIbanValidator {
  private static BLZ_WHITELIST = [
    '51390000',
    '50190000',
    '40361906',
    '42861387',
    '70093200',
    '24060300',
    '61191310',
    '26991066',
    '20090700',
  ];
  private static IBAN_BLZ_RANGE = { start: 4, end: 12 };
  private blz: string;

  constructor(iban: string) {
    this.blz = RtpOnboardingIbanValidator.extractBlz(iban);
  }

  public isBlzAllowed(): boolean {
    return RtpOnboardingIbanValidator.BLZ_WHITELIST.includes(this.blz);
  }
  private static extractBlz(iban: string): string {
    return iban.substring(this.IBAN_BLZ_RANGE.start, this.IBAN_BLZ_RANGE.end);
  }
}
export function ibanBlzWhitelistValidator(control: AbstractControl, field: FormlyFieldConfig): ValidationErrors | null {
  if (!control.value) {
    return null;
  }
  const iban = control.value as string;
  const ibanValidator = new RtpOnboardingIbanValidator(iban);
  return ibanValidator.isBlzAllowed() ? null : { ibanBlzForbidden: true };
}
