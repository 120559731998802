import { FormlyExtension, FormlyFieldConfig } from '@ngx-formly/core';
import { StringUtil } from '@shared/string-util';
import get from 'lodash.get';
export class CombineExtension implements FormlyExtension {
  prePopulate(field: FormlyFieldConfig) {
    const fieldProps = field.props ?? {};
    const fieldType = (field.type as string) ?? '';
    const fieldKey = (field.key as string) ?? '';
    if (!fieldProps.combine) {
      return;
    }
    if (!['text', 'masked', 'numeric'].includes(fieldType)) {
      return;
    }
    field.form?.get(fieldKey)?.setValue('Test');
    if (Array.isArray(fieldProps.combineKeys)) {
      const fieldValues = Object.values(fieldProps.combineKeys).map((key) => get(field.model, key));
      const combinedValue = fieldValues.join(' ');
      if (fieldKey === '' && !StringUtil.isBlank(combinedValue)) {
        field.defaultValue = combinedValue;
      }
    }
  }
}
export function MultiBindExtensionFactory() {
  return {
    extensions: [
      {
        name: 'combine',
        extension: new CombineExtension(),
      },
    ],
  };
}
