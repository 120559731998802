import { Component } from '@angular/core';
import { Organization } from '@app/states/organization/organization.model';
import { OrganizationStateService } from '@app/states/organization/organization-state.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'ruv-contact-popup',
  templateUrl: './contact-popup.component.html',
  styleUrls: ['./contact-popup.component.scss'],
})
export class ContactPopupComponent {
  public organization$!: Observable<Organization | undefined>;

  constructor(stateService: OrganizationStateService) {
    this.organization$ = stateService.organization$;
  }
}
