import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';

declare namespace _etracker {
  function sendEvent(event: et_UserDefinedEvent): void;
}

declare class et_UserDefinedEvent {
  constructor(
    object?: string,
    category?: EtrackerCategory,
    action?: EtrackerAction,
    type?: EtrackerType,
    value?: string,
  );
}

export type EtrackerCategory = 'form' | 'info' | 'send';
export type EtrackerAction = 'click';
export type EtrackerType = 'Klick';

@Injectable({
  providedIn: 'root',
})
export class EtrackerService {
  public init() {
    if (environment.featureFlags.eTracker.enabled) {
      const eTrackerScriptElement = document.querySelector('#_etLoader') as HTMLScriptElement;

      if (eTrackerScriptElement) {
        eTrackerScriptElement.setAttribute('data-block-cookies', `${environment.featureFlags.eTracker.blockCookies}`);
        eTrackerScriptElement.setAttribute('data-secure-code', environment.featureFlags.eTracker.secureCode);
        eTrackerScriptElement.src = '//code.etracker.com/code/e.js';
      }
    }
  }

  public sendClickEvent(object: string, category: EtrackerCategory) {
    /* If the eTracker code is not loaded, the reference '_etracker' does not exist.
       That would result in a ReferenceError (see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Errors/Not_defined)
       Checking for typeof is a workaround for this problem. */
    if (typeof _etracker !== 'undefined') {
      const event = new et_UserDefinedEvent(object, category, 'click', 'Klick');
      try {
        _etracker.sendEvent(event);
      } catch (error) {
        console.warn('ETRACKER IS NOT AVAILABLE \n', error);
      }
    }
  }
}
