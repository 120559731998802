import { Address, Case, Customer } from '../case.model';

export interface DefaultCase extends Case {
  customer: DefaultCustomer;

  // not needed in frontend
  landingPageUrlParameter?: string;
  customData?: { [key: string]: string };
}

export interface DefaultCustomer extends Customer {
  customerNumber?: string;
  private?: ContactInfo;
  business?: ContactInfo;
  nameAffix?: string;
  address: Address;
  postOfficeBox?: PostOfficeBox;
  consent: Consent;

  // frontend-only fields
  contactEmail?: string; // This field is solely for the purpose of caching the initial email address for translation in the success page
}

export interface ContactInfo {
  phoneNumber?: string;
  mobileNumber?: string;
  emailAddress?: string;
}

export interface PostOfficeBox {
  city?: string;
  postalCode?: string;
  postOfficeBoxNumber?: string;
  countryCode?: string;
}

export interface Consent {
  email: ConsentData;
  phone: ConsentData;
}

export interface ConsentData {
  customerConsent: ConsentType;
  customerConsentedAt?: string;
  customerConsentUpdateAt?: string;
}

export enum ConsentType {
  ACCEPTED = 'ACCEPTED',
  DENIED = 'DENIED',
  NO_COMMENT = 'NO_COMMENT',
}
