import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { SharedModule } from '@shared/shared.module';
import { CodeComponent } from './code.component';

const routes: Routes = [
  {
    path: '',
    component: CodeComponent,
  },
];

@NgModule({
  declarations: [CodeComponent],
  imports: [CommonModule, TranslateModule.forChild(), RouterModule.forChild(routes), ButtonsModule, SharedModule],
  exports: [CodeComponent],
})
export class CodeModule {}
