import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { TextBoxComponent } from '@progress/kendo-angular-inputs';

@Component({
  selector: 'kendo-textbox-field',
  template: `
    <kendo-textbox
      [class.k-state-invalid]="showError"
      [placeholder]="to.placeholder ?? ''"
      [readonly]="to.readonly ?? false"
      [clearButton]="to.showClearButton"
      [clearButtonIcon]="to.clearButtonIcon"
      [errorIcon]="to.errorIcon"
      [showErrorIcon]="to.errorIcon ? 'initial' : false"
      [formControl]="props.disabled ? disabledControl : formControl"
      [formlyAttributes]="field"
      [maxlength]="maxLength"
      [attr.data-cy]="props['data-cy'] ?? 'kendo-textbox-field'"
    >
      <ng-template *ngIf="to.icon" kendoTextBoxPrefixTemplate>
        <i [ngClass]="to.icon"></i>
      </ng-template>
    </kendo-textbox>
  `,
})
export class KendoTextBoxField extends FieldType<FieldTypeConfig> implements AfterViewInit {
  @ViewChild(TextBoxComponent) textBoxRef!: TextBoxComponent;

  get maxLength() {
    // Needs to be casted to number, because props.maxLength can be undefined.
    // If props.maxLength is undefined the maxlength-attribute on the kendo-textbox will be removed
    return this.props.maxLength as number;
  }

  ngAfterViewInit() {
    this.disableAutoComplete();
  }

  get disabledControl() {
    return new FormControl({ value: this.formControl.value, disabled: true });
  }

  override defaultOptions = {
    props: {
      showClearButton: false,
    },
  };

  private disableAutoComplete() {
    // autocomplete=off is bugged in chrome, suggestion is to fill with non-conform value
    // see: https://developer.mozilla.org/en-US/docs/Web/Security/Securing_your_site/Turning_off_form_autocompletion
    const elementRef = this.textBoxRef.input as ElementRef<HTMLInputElement>;
    elementRef.nativeElement.setAttribute('autocomplete', 'disable-autocomplete');
  }
}
