import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'kendo-exandable-field',
  template: `
    <kendo-panelbar [animate]="to.animate">
      <kendo-panelbar-item [expanded]="first || to.expandAll" *ngFor="let f of field.fieldGroup; let first = first">
        <ng-template kendoPanelBarItemTitle>
          <i *ngIf="f.props?.icon" [ngClass]="f.props?.icon"></i>
          <span>{{ f.props?.label ?? '' }}</span>
        </ng-template>
        <ng-template kendoPanelBarContent>
          <div class="exandable-content">
            <formly-field [field]="f"></formly-field>
          </div>
        </ng-template>
      </kendo-panelbar-item>
    </kendo-panelbar>
  `,
  styles: [
    `
      .exandable-content {
        padding: 1rem;
      }
    `,
  ],
})
export class KendoExpandableField extends FieldType<FieldTypeConfig> {}
