import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ParseEmailPipe } from './parse-email.pipe';
import { ParsePaceholdersPipe } from './parse-placeholders.pipe';
import { IsEmpty } from './is-empty.pipe';
import { KKinkOptionsPipe } from './kkink-options.pipe';

const PIPES = [IsEmpty, ParseEmailPipe, ParsePaceholdersPipe, KKinkOptionsPipe];

@NgModule({
  declarations: [PIPES],
  imports: [CommonModule],
  exports: [PIPES],
})
export class PipesModule {}
