import { AbstractControl, ValidationErrors } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';

export function alphaNumericValidator(control: AbstractControl, field: FormlyFieldConfig): ValidationErrors | null {
  if (!control.value) {
    return null;
  }

  const validCharacters = control.value && /^[a-zA-Z0-9\s*]*$/.test(control.value);
  return validCharacters ? null : { alphaNumeric: true };
}
