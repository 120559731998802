import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'kendo-output-field',
  template: `
    <p
      [ngClass]="props.textClass"
      [style.textAlign]="props.textAlign || 'justify'"
      [safeHTML]="props.label ? (formControl | formParse | translate) : props.text"
      [attr.data-cy]="props['data-cy'] ?? 'kendo-output-field'"
    ></p>
  `,
  styles: [],
})
export class KendoOutputField extends FieldType<FieldTypeConfig> {}
