import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OrganizationResolver } from './states/organization/organization.resolver';
import { ViewsModule } from './views/views.module';
import { AppIds, AppRoutes } from './app-routes';

const routes: Routes = [
  {
    path: '',
    redirectTo: `/${AppRoutes.Default}`,
    pathMatch: 'full',
  },
  {
    path: `:${AppIds.ConfigId}`,
    resolve: {
      organization: OrganizationResolver,
    },
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes), ViewsModule],
  exports: [RouterModule],
})
export class AppRoutingModule {}
