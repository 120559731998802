import { Pipe, PipeTransform } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Pipe({ name: 'formParse' })
export class FormParsePipe implements PipeTransform {
  transform(form: UntypedFormControl): string {
    const formValue = form.value;
    if (typeof formValue === 'boolean') {
      return this.toTranslatedBoolean(formValue);
    }
    if (typeof formValue === 'string') {
      return this.toEmptyString(formValue);
    }
    return formValue ?? '-';
  }

  private toTranslatedBoolean(formValue: any) {
    return formValue ? 'FORM.YES' : 'FORM.NO';
  }

  private toEmptyString(formValue: any) {
    return formValue === '' ? '-' : formValue;
  }
}
