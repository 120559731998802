<div class="container">
  <div class="centering">
    <ng-container [ngSwitch]="currentCaseType">
      <ng-container *ngSwitchCase="CaseType.KKINK">
        <header class="title" data-cy="header-text">
          {{ 'VERIFICATION_NOT_POSSIBLE.KKINK.EXPIRED_LINK.HEADER.TEXT' | translate }}
        </header>
        <p
          class="text"
          data-cy="description-text"
          safeHTML="{{ 'VERIFICATION_NOT_POSSIBLE.KKINK.EXPIRED_LINK.DESCRIPTION.TEXT' | translate }}"
          F
        ></p>
      </ng-container>

      <ng-container *ngSwitchCase="CaseType.RTP">
        <header class="title" data-cy="header-text">
          {{ 'VERIFICATION_NOT_POSSIBLE.RTP_ONBOARDING.EXPIRED_LINK.HEADER.TEXT' | translate }}
        </header>
        <p
          class="text"
          data-cy="description-text"
          safeHTML="{{ 'VERIFICATION_NOT_POSSIBLE.RTP_ONBOARDING.EXPIRED_LINK.DESCRIPTION.TEXT' | translate }}"
        ></p>
      </ng-container>

      <ng-container *ngSwitchDefault="">
        <div class="title emphasize" data-cy="header-text">
          {{ 'EXPIRED_LINK.HEADER.TEXT' | translate }}
        </div>
        <p class="text" data-cy="description-text" safeHTML="{{ 'EXPIRED_LINK.DESCRIPTION.TEXT' | translate }}"></p>
      </ng-container>
    </ng-container>
  </div>
</div>
