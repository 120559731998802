<div class="container">
  <div *ngIf="organization$ | async as organization" class="text centering">
    <div data-cy="welcome-text" [safeHTML]="organization.welcomeText | parsePlaceholders: organization"></div>
    <div
      data-cy="introduction-text"
      [safeHTML]="organization.codeLandingpageText | parsePlaceholders: organization"
    ></div>
  </div>

  <div class="centering">
    <form class="k-form" [formGroup]="form" *ngIf="fields$ | async as fields" (ngSubmit)="submit(model)">
      <formly-form [form]="form" [fields]="fields" [model]="model"></formly-form>
    </form>
  </div>
</div>
