<div class="container">
  <div class="centering">
    <ng-container *ngIf="organization$ | async as organization; else defaultText">
      <ng-container *ngIf="organization.notAuthorizedText; else defaultText">
        <div
          data-cy="description-text"
          [safeHTML]="organization.notAuthorizedText | parsePlaceholders: organization"
        ></div>
      </ng-container>
    </ng-container>

    <ng-template #defaultText>
      <header class="title" data-cy="header-text">
        {{ 'NOT_AUTHORIZED.HEADER.TEXT' | translate }}
      </header>
      <p class="text" data-cy="description-text">
        {{ 'NOT_AUTHORIZED.DESCRIPTION.TEXT' | translate }}
      </p>
    </ng-template>
  </div>
</div>
