import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'kendo-checkbox-complex-field',
  template: `
    <div class="checkbox-container">
      <ng-container *ngFor="let option of props.options | formlySelectOptions: field | async as options; let i = index">
        <div class="checkbox-item" *ngIf="!option.disabled">
          <input
            type="radio"
            kendoCheckBox
            [id]="id + '_' + i"
            [name]="field.name || id"
            [value]="option.value"
            [formControl]="formControl"
            [formlyAttributes]="field"
            (click)="click(options, i)"
            rounded="none"
            [attr.data-cy]="props['data-cy'] ?? 'kendo-checkbox-complex-field'"
          />
          <label class="k-radio-label" [for]="id + '_' + i" [safeHTML]="option.label | translate"> </label>
        </div>
      </ng-container>
    </div>
  `,
  styles: [
    `
      .checkbox-container {
        padding: 1rem;
        border: 2px solid lightgray;
        border-radius: 5px;
      }

      .checkbox-item {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        .k-checkbox {
          border-radius: 4px;

          &:focus,
          &.k-focus {
            box-shadow: 0 0 0 2px rgba(0, 25, 87, 0.3);
            outline: none;
          }
        }
      }

      .k-checkbox:indeterminate {
        background-image: none;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KendoCheckboxComplexField extends FieldType<FieldTypeConfig> implements OnInit {
  override defaultOptions = {
    props: {
      options: [],
    },
  };

  ngOnInit() {
    const label = this.field.props.useAlternateText ? this.field.props.alternateText : this.field.props.text;

    this.field.props.options = [
      {
        label: label,
        value: this.field.props.checked,
      },
      {
        value: this.field.props.unchecked,
        disabled: true,
      },
    ];
  }

  click(options: any[], i: number) {
    const checkedValue = options[0].value;
    const uncheckedValue = options[1].value;
    const currentValue = this.formControl.value;

    if (options[i].value === currentValue) {
      this.formControl.setValue(uncheckedValue);
    } else {
      this.formControl.setValue(checkedValue);
    }
  }
}
