import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { DirectiveModule } from './directives/directive.module';
import { PipesModule } from './pipes/pipes.module';
import { RuVFormsModule } from './ruv-forms/ruv-forms.module';

const SHARED_MODULES = [HttpClientModule, RuVFormsModule, DirectiveModule, PipesModule];

@NgModule({
  declarations: [],
  imports: [...SHARED_MODULES],
  exports: [...SHARED_MODULES],
})
export class SharedModule {}
