/* eslint-disable @angular-eslint/no-host-metadata-property */
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldGroupTypeConfig, FieldType } from '@ngx-formly/core';

@Component({
  selector: 'kendo-group',
  template: `
    <kendo-label
      class="form-label"
      [class.label__center]="props.labelPosition === 'center'"
      [ngClass]="props.labelClass"
      *ngIf="props.label && props.hideLabel !== true"
      [for]="id"
      [text]="props.label | translate"
      [attr.data-cy]="props['data-cy'] ?? 'kendo-group-label'"
    >
      <kendo-label
        *ngIf="props.required && props.hideRequiredMarker !== true"
        aria-hidden="true"
        class="form-marker"
        text="({{ 'FORM.MARKER.REQUIRED' | translate }})"
      ></kendo-label>
      <kendo-label
        *ngIf="props.optional && props.hideOptionalMarker !== true"
        aria-hidden="true"
        class="form-marker"
        text="({{ 'FORM.MARKER.OPTIONAL' | translate }})"
      ></kendo-label>
    </kendo-label>
    <formly-field
      *ngFor="let f of field.fieldGroup"
      [field]="f"
      [class.group-inline]="field.props.inline"
    ></formly-field>
  `,
  host: {
    '[class]': 'field.fieldGroupClassName || ""',
  },
  styles: [
    `
      :host {
        padding: 1rem 0;
      }

      .form-label {
        font-weight: bold;
      }

      .form-marker {
        padding-left: 0.25rem;
      }

      .label__center {
        justify-content: center;
      }

      .form-label,
      .form-marker {
        display: inline;
        ::ng-deep > label {
          display: inline;
        }
      }
      .group-inline ::ng-deep {
        &,
        & .form-field,
        & .info-icon,
        & .text {
          display: inline !important;
          margin: 0 !important;
          padding: 0 !important;
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KendoGroupField extends FieldType<FieldGroupTypeConfig> {}
