import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { Buffer } from 'buffer';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private authenticated$ = new BehaviorSubject<boolean>(false);

  public getToken(): string {
    return Buffer.from(`${environment.basicAuthUser}:${environment.basicAuthPassword}`).toString('base64');
  }

  public isAuthenticated(): Observable<boolean> {
    return this.authenticated$.asObservable();
  }

  public setAuthentication(authenticate: boolean) {
    this.authenticated$.next(authenticate);
  }
}
