import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { SharedModule } from '@shared/shared.module';
import { CoreComponent } from './core.component';

const routes: Routes = [
  {
    path: '',
    component: CoreComponent,
  },
];

@NgModule({
  declarations: [CoreComponent],
  imports: [CommonModule, TranslateModule.forChild(), RouterModule.forChild(routes), ButtonsModule, SharedModule],
  exports: [CoreComponent],
})
export class CoreModule {}
