import { AbstractControl, ValidationErrors } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';

export const MAX_VERIFICATION_ATTEMPTS = 5;

export function verificationValidator(control: AbstractControl, field: FormlyFieldConfig): ValidationErrors | null {
  return field.model.verificationAttemptsLeft < MAX_VERIFICATION_ATTEMPTS && control.value === null
    ? { verification: true }
    : null;
}
